import { getErrors } from '@on3/ui-lib/utils/yup';
import { ReactNode, useEffect, useReducer } from 'react';
import { SchemaOf } from 'yup';

enum ActionType {
  UPDATE = 'update',
  SUBMIT = 'submit',
  ERROR = 'error',
  PROCESS = 'process',
}

type Action =
  | { type: ActionType.UPDATE; payload: unknown }
  | { type: ActionType.SUBMIT }
  | { type: ActionType.ERROR; payload: Record<string, string> }
  | { type: ActionType.PROCESS; payload: boolean };

interface State {
  values: unknown;
  errors: Record<string, string>;
  processing: boolean;
}

const initialState: State = {
  values: {},
  errors: {},
  processing: false,
};

function formReducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.UPDATE:
      return { ...state, values: action.payload };
    case ActionType.SUBMIT:
      return { ...state, processing: true, errors: {} };
    case ActionType.ERROR:
      return { ...state, errors: action.payload };
    case ActionType.PROCESS:
      return { ...state, processing: action.payload };
    default:
      return state;
  }
}

interface FormProps {
  values: unknown;
  schema: SchemaOf<unknown>;
  onSubmit: (values: any) => Promise<void>;
  children: (state: State, dispatch: React.Dispatch<Action>) => ReactNode;
  className?: string;
}

export const Form = ({
  values,
  schema,
  onSubmit,
  children,
  className,
}: FormProps) => {
  const [state, dispatch] = useReducer(formReducer, {
    ...initialState,
    values,
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch({ type: ActionType.SUBMIT });

    try {
      const validated = await schema.validate(state.values, {
        abortEarly: false,
      });

      await onSubmit(validated);
    } catch (error: any) {
      dispatch({ type: ActionType.ERROR, payload: getErrors(error) });
    } finally {
      dispatch({ type: ActionType.PROCESS, payload: false });
    }
  };

  useEffect(() => {
    dispatch({ type: ActionType.UPDATE, payload: values });
  }, [values]);

  return (
    <form className={className} onSubmit={handleSubmit}>
      {children(state, dispatch)}
    </form>
  );
};
