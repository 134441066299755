import { useAuth } from '@on3/ui-lib';
import clsx from 'clsx';
import useAthlete from 'contexts/AthleteProvider';
import Link from 'next/link';

import styles from './AthleteClaimButton.module.scss';

interface AthleteClaimButtonProps {
  buttonText?: string;
  className?: string;
}

export const AthleteClaimButton: React.FC<AthleteClaimButtonProps> = ({
  buttonText = 'Claim Profile',
  className,
}) => {
  const { athlete, athleteInPro, hasBaseProfile } = useAthlete();
  const { isVerifiedPlayer } = useAuth();
  const baseUrl = `/db/${athlete.slug}`;
  const link = baseUrl + (hasBaseProfile ? '/claim-profile/' : '/link/');

  if (athleteInPro) {
    return null;
  }

  return (
    <>
      {!isVerifiedPlayer(athlete?.key) && !athlete?.athleteVerified && (
        <Link className={clsx(styles.button, className)} href={link}>
          {buttonText}
        </Link>
      )}
    </>
  );
};
