import { NoSsr } from '@mui/material';
import { On3IconSearch } from '@on3/icons';
import { useAuth, useSite } from '@on3/ui-lib';
import { AthleteClaimButton } from 'components/Db/Profile/AthleteClaim/AthleteClaimButton';
import { useHeader } from 'components/Header';
import { UserMenu } from 'components/Header/UserMenu';
import { AdFreeCta } from 'components/UI/AdFreeCta';
import { TextLink } from 'components/UI/Link/TextLink';
import useAthlete from 'contexts/AthleteProvider';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import styles from './Auth.module.scss';

export const Auth = ({ isSubscribe }: { isSubscribe?: boolean }) => {
  const { user } = useAuth();
  const { currentSite } = useSite();
  const { athlete, hasBaseProfile } = useAthlete();
  const router = useRouter();
  const {
    themes: {
      isElite,
      isSoftballAmerica,
      isNil,
      isHighSchool,
      isLogin,
      isTeam,
    },
  } = useHeader();

  const showClaimProfile = hasBaseProfile && !athlete?.athleteVerified;
  const returnto = router.query.returnto as string;
  const hasReturnTo = !!returnto;

  const loginPathname = `${isTeam ? currentSite?.url : ''}/login/`;

  const loginQuery = useMemo(() => {
    if (hasReturnTo) {
      return `?returnto=${returnto}`;
    } else if (isLogin) {
      return '';
    }

    return `?returnto=${router.asPath}`;
  }, [hasReturnTo, isLogin, returnto, router.asPath]);

  const loginLink = `${loginPathname}${loginQuery}`;
  const noSub = !user?.has;
  const showBasicJoin =
    noSub &&
    !isTeam &&
    !isSubscribe &&
    !isElite &&
    !isSoftballAmerica &&
    !isHighSchool &&
    !showClaimProfile;
  const showPianoJoin =
    noSub &&
    (isTeam || isSoftballAmerica) &&
    !isSubscribe &&
    !isHighSchool &&
    !showClaimProfile;

  return (
    <div className={styles.block} data-menu-ui="auth">
      {!isNil && !isSubscribe && (
        <Link
          className={styles.search}
          href="/db/search/"
          title="On3 Database Hub"
        >
          <On3IconSearch fill="currentColor" height="16px" width="16px" />
        </Link>
      )}
      {showBasicJoin && (
        <TextLink
          className={styles.joinLink}
          data-join
          href="/join/"
          underline="none"
        >
          {currentSite?.subscribeText || 'Join'}
        </TextLink>
      )}
      {showPianoJoin && (
        <div className={styles.pianoJoin}>
          <NoSsr>
            <div id="pianoJoin" />
          </NoSsr>
        </div>
      )}
      {showClaimProfile && <AthleteClaimButton className={styles.claim} />}
      {!showClaimProfile && <AdFreeCta hide={isSubscribe} />}
      {user?.a ? (
        <UserMenu />
      ) : (
        <Link className={styles.login} href={loginLink}>
          Login
        </Link>
      )}
    </div>
  );
};
